<template>
    <div>
        <navBar nav="我的广告" />
        <div style="width: 100%;height: 2.5445vw;background-color: #eee;"></div>
        <van-empty description="暂无数据" v-if="myData.length == 0" />
        <div style="background-color: #fff;" v-else>
            <div class="myBox" v-for="item in myData" @click="openPopup(item)">
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <div style="display: flex;align-items: center;">
                        <img src="../../assets/img/avatar.png" alt=""
                            style="width: 10.1781vw;height: 10.1781vw;margin-right: 1.2723vw;">
                        <span class="userName">{{ item.member.username }}</span>
                    </div>
                    <span class="status">{{ statuS[item.status] }}</span>
                </div>
                <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 2.5445vw;">
                    <span class="number">数量 {{ item.number }}{{ item.coin.name }}</span>
                    <span class="status1">{{ advertiseTypeS[item.advertiseType] }}</span>
                </div>
                <div style="display: flex;align-items: flex-end;justify-content: space-between;margin-top: 2.5445vw;">
                    <div>
                        <div class="number">手续费 {{ item.commission }}</div>
                        <div class="number">限额 {{ item.minLimit }}~{{ item.maxLimit }}{{ item.country.localCurrency }}
                        </div>
                    </div>
                    <div class="number1">{{ Number(String(item.remainAmount).replace(/^(.*\..{4}).*$/,
            "$1")) }}{{ item.coin.name }}</div>
                </div>
            </div>
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '15%' }">
            <div style="display: flex;justify-content: space-around;margin-top: 7.6336vw;" v-if="typeUpOrDown == 1">
                <div style="width: 11.4504vw;display: flex;flex-wrap: wrap;justify-content: center;" @click="update">
                    <img src="../../assets/img/icon_edit_ads.png" alt="" style="width: 11.4504vw;height: 11.4504vw;">
                    <span class="text">修改</span>
                </div>
                <div style="width: 11.4504vw;display: flex;flex-wrap: wrap;justify-content: center;" @click="up">
                    <img src="../../assets/img/icon_up_ads.png" alt="" style="width: 11.4504vw;height: 11.4504vw;">
                    <span class="text">上架</span>
                </div>
                <div style="width: 11.4504vw;display: flex;flex-wrap: wrap;justify-content: center;" @click="doDelete">
                    <img src="../../assets/img/icon_delete_ads.png" alt="" style="width: 11.4504vw;height: 11.4504vw;">
                    <span class="text">删除</span>
                </div>
            </div>
            <div style="display: flex;justify-content: space-around;margin-top: 7.6336vw;" v-else>
                <div style="width: 11.4504vw;display: flex;flex-wrap: wrap;justify-content: center;" @click="down">
                    <img src="../../assets/img/icon_down_ads.png" alt="" style="width: 11.4504vw;height: 11.4504vw;">
                    <span class="text">下架</span>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import navBar from "../../components/navBar/index.vue"
import { Dialog } from 'vant';
export default {
    components: {
        navBar
    },
    data() {
        return {
            myData: [],
            statuS: {
                0: '上架中',
                1: '已下架'
            },
            advertiseTypeS: {
                0: '购买',
                1: '出售'
            },
            show: false,
            typeUpOrDown: '',
            ids: '',
            advertiseTypeUpdate: ''
        }
    },
    created() {
        this.getMy()
    },
    methods: {
        //获取我的广告
        getMy() {
            this.$http.post(this.host + '/otc/advertise/all', {}).then(res => {
                this.myData = res.data.data.content
            })
        },
        openPopup(item) {
            this.typeUpOrDown = item.status
            this.advertiseTypeUpdate = item.advertiseType
            this.ids = item.id
            this.show = true
        },
        //上架
        up() {
            this.$http.post(this.host + '/otc/advertise/on/shelves', { id: this.ids }).then(res => {
                console.log(res, '0pikjnmn');
                if (res.data.code == 500) {
                    this.$toast(res.data.message)
                } else {
                    this.$toast('上架成功')
                    this.show = false
                    this.getMy()
                }
            }).catch(err => {
                this.$toast(err)
            })
        },
        //下架
        down() {
            this.$http.post(this.host + '/otc/advertise/off/shelves', { id: this.ids }).then(res => {
                console.log(res, '0pikjnmn');
                if (res.data.code == 500) {
                    this.$toast(res.data.message)
                } else {
                    this.$toast('上架成功')
                    this.show = false
                    this.getMy()
                }
            }).catch(err => {
                this.$toast(err)
            })


        },
        //删除
        doDelete() {
            Dialog.confirm({
                title: '数据删除',
                message: '确定要删除该数据吗?',
            })
                .then(() => {
                    this.$http.post(this.host + '/otc/advertise/delete', { id: this.ids }).then(res => {
                        console.log(res, '0pikjnmn');
                        if (res.data.code == 500) {
                            this.$toast(res.data.message)
                        } else {
                            this.$toast(res.data.message)
                            this.show = false
                            this.getMy()
                        }
                    }).catch(err => {
                        this.$toast(err)
                    })
                })
                .catch(() => {
                    // on cancel
                });

        },
        update() {
            if (this.advertiseTypeUpdate == 1) {
                this.$router.push({
                    path: '/otcAddAd',
                    query: {
                        id: this.ids
                    }
                })
            } else {
                this.$router.push({
                    path: '/otcAddAdBuy',
                    query: {
                        id: this.ids
                    }
                })
            }
        }

    }
}
</script>

<style scoped>
.text {
    color: #3861FB;
    font-size: 3.8168vw;
    font-size: 700;
    margin-top: 1.0178vw
}

.status {
    font-size: 3.3079vw;
    color: rgb(246, 193, 76);
    letter-spacing: .5089vw;
}

.status1 {
    font-size: 3.3079vw;
    letter-spacing: .5089vw;
}

.number {
    font-size: 3.0534vw;
    color: #aaa;
}

.number1 {
    font-size: 3.8168vw;
}

.userName {
    font-size: 3.8168vw;
    font-weight: bold;
}

.myBox {
    width: 100%;
    border-bottom: .2545vw solid #eee;
    height: 38.1679vw;
    padding: 0 5.0891vw;
    padding-top: 2.5445vw;
}

.myBox:last-child {
    width: 100%;
    border-bottom: none;
    height: 38.1679vw;
    padding: 0 5.0891vw;
    padding-top: 2.5445vw;
}
</style>